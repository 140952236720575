.desktop-menu {
  display: "flex";
  flex-grow: 1;
  flex-direction: "column-reverse";
}

.mobile-menu {
  display: none;
}

@media (max-width: 768px) {
  .desktop-menu {
    display: none;
  }

  .mobile-menu {
    display: block;
    padding-right: 10px;

  }
}