.slick-track {
  width: 100%;
  height: auto;
}

#Logo {
  font-size: 1.8rem;
  margin-left: 5%;
  color: #080808c7;
}
/* 
.slider-item {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.content {
  position: absolute;
  bottom: 80px;
  left: 20px;
  color: white;
  text-align: center;
} */
 
/* 
body {
  display:flex; flex-direction:column; justify-content:center;
  min-height:100vh;
} */

/* The following is the header sticky code but not used right now since it block the top visual of the scroll Element */
/* .ant-layout-header{

  position:fixed;
  width:100%;
  z-index: 1000;
} */

/* 
Credit:
1. https://stackoverflow.com/questions/42345641/antd-fixed-header-in-layout
*/